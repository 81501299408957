import { cilPuzzle, cilSpeedometer } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
    CBadge,
    CNavGroup,
    CNavItem,
    CNavTitle,
    CSidebar,
    CSidebarBrand,
    CSidebarNav,
    CSidebarToggler,
    CSpinner
} from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react'
import {
    Link,
    NavLink,
    Outlet,
    useNavigate,
    useParams
} from 'react-router-dom';
import { getChatsOfUser, viewUser } from '../Helper/Index.Api';
import strings from '../strings';
import "./chat.css"
import AppTitle from '../AppTitle';

const ChatList = () => {
    const { chat, id } = useParams();
    const [chatList, setChatList] = useState([]);
    const [userData, setUserData] = useState();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    // getting user chats
    useEffect(() => {
        setLoading(true)
        let data = {
            curruntUser: id,
            searchText: "",
            isGroupChat: ""
        }
        getChatsOfUser(data, chat).then((res) => {
            if (res.err === 200) {
                setChatList(res.data);
            }
        })
            .catch((err) => console.log(err));
        viewUser({ _id: id }).then((res) => {
            setUserData(res.data);
        }).catch((err) => {
            console.log(err)
        });
        setLoading(false)
    }, []);


    let NavStyle = ({ isActive }) => {
        return {
            color: isActive ? ' black ' : 'black',
            position: isActive ? 'relative' : ''
        }
    }

    function NewDate(date) {
        let currentDate = new Date();
        currentDate.setHours(12, 0, 0, 0);
        if (currentDate > date) {
            return true;
        }
        else {
            return false;
        }

    }


    // listLoading ? <div className="d-flex justify-content-center"><CSpinner style={
    //     { marginTop: "40px" }
    // }
    //     color="info" /></div>


    return (
        <>
            {loading ? (<div className="d-flex justify-content-center">
                <CSpinner style={{ marginTop: "40px" }} color='info' />
            </div>) : (<div>
                <AppTitle title={
                    `${chat.charAt(0).toUpperCase() + chat.slice(1)
                    } Chat >`
                }
                    showbtn={true}
                    path={
                        `/viewuser/${id}`
                    }
                    image={userData?.userAvatar?.cdnlocation ? userData?.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"}
                    name={userData?.first_Name + " " + userData?.last_Name}
                    email={userData?.email}
                /> {
                    userData ? (
                        <>
                            <div className="container-fluid mb-3 mt-3">
                                <div className="row mx-xl-5 mt-lg-5 mx-2 mt-1 rounded-4" style={{ backgroundColor: "white" }}>
                                    <div className="col-lg-5 col-xl-4">
                                        <div className='chatlist_height d-lg-block d-none'
                                            style={
                                                {
                                                    background: "white",
                                                    overflowY: "scroll",
                                                    borderRight: "1px solid #80808050"
                                                }
                                            }>
                                            {loading ? <CSpinner color='info' /> : <ul className="nav nav-tabs flex-column p-2"
                                                style={
                                                    { borderBottom: "none" }
                                                }>
                                                {
                                                    loading ? <CSpinner color='info' /> : chatList?.length > 0 ? chatList?.map((data, index) => {
                                                        if (data._id === "") { } else {
                                                            if (data.isGroupChat === false) {
                                                                let Noduplicates = [];
                                                                data?.users?.filter((user) => (user._id !== id) ? Noduplicates.push(user) : "")
                                                                return <li className="nav-item"
                                                                    key={index}>
                                                                    <NavLink className="nav-link nav-light rounded-3 my-link"
                                                                        style={NavStyle}
                                                                        exact
                                                                        activeclassname="active"
                                                                        to={
                                                                            `/messages/${chat}/` + id + "/" + data._id
                                                                        }>
                                                                        {
                                                                            Noduplicates[0]?.userAvatar?.cdnlocation ? (
                                                                                <>
                                                                                    <img src={
                                                                                        strings.MEDIAPATH + Noduplicates[0]?.userAvatar?.cdnlocation
                                                                                    }
                                                                                        style={
                                                                                            {
                                                                                                width: "50px",
                                                                                                height: "50px"
                                                                                            }
                                                                                        }
                                                                                        className='rounded-circle'
                                                                                        alt="" />
                                                                                    &nbsp;&nbsp; {
                                                                                        (Noduplicates[0]?.first_Name + " " + Noduplicates[0]?.last_Name).length > 10 ? ((Noduplicates[0]?.first_Name + " " + Noduplicates[0]?.last_Name).slice(0, 10) + "...") : ((Noduplicates[0]?.first_Name + " " + Noduplicates[0]?.last_Name))
                                                                                    } </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src="https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                                                        style={
                                                                                            {
                                                                                                width: "50px",
                                                                                                height: "50px"
                                                                                            }
                                                                                        }
                                                                                        className='rounded-circle'
                                                                                        alt="image" />
                                                                                    &nbsp;&nbsp; {
                                                                                        (Noduplicates[0].first_Name + " " + Noduplicates[0].last_Name).length > 12 ? ((Noduplicates[0].first_Name + " " + Noduplicates[0].last_Name).slice(0, 12) + "...") : ((Noduplicates[0].first_Name + " " + Noduplicates[0].last_Name))
                                                                                    } </>
                                                                            )
                                                                        } </NavLink>
                                                                </li>
                                                            }
                                                            if (data.isGroupChat === true) {
                                                                return <li className="nav-item"
                                                                    key={index}>
                                                                    <NavLink className="nav-link nav-light rounded-3 my-link"
                                                                        style={NavStyle}
                                                                        exact
                                                                        activeclassname="active"
                                                                        to={
                                                                            `/messages/${chat}/` + id + "/" + data._id
                                                                        }>
                                                                        {
                                                                            data?.chatLogo.length === 0 ? (
                                                                                <>
                                                                                    <img src="https://th.bing.com/th?q=People+Group+Flat+Icon&w=120&h=120&c=1&rs=1&qlt=90&cb=1&dpr=1.3&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247"
                                                                                        style={
                                                                                            {
                                                                                                width: "50px",
                                                                                                height: "50px"
                                                                                            }
                                                                                        }
                                                                                        className='rounded-circle'
                                                                                        alt="group" />
                                                                                    &nbsp;&nbsp; {
                                                                                        data.chatName
                                                                                    } </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={
                                                                                        strings?.MEDIAPATH + data?.chatLogo[0]?.cdnlocation
                                                                                    }
                                                                                        style={
                                                                                            {
                                                                                                width: "50px",
                                                                                                height: "50px"
                                                                                            }
                                                                                        }
                                                                                        className='rounded-circle'
                                                                                        alt="image1" />
                                                                                    &nbsp;&nbsp; {
                                                                                        data.chatName
                                                                                    } </>
                                                                            )
                                                                        } </NavLink>
                                                                </li>
                                                            }
                                                        }
                                                    }) : <div style={
                                                        {
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            marginTop: "25vh",
                                                            marginBottom: "25vh",
                                                            // height:"100%"
                                                        }
                                                    }>No chats found</div>
                                                } </ul>}
                                        </div>
                                        <div className='rounded-4 chatlist_height d-lg-none d-block'
                                            style={
                                                {
                                                    background: "white",
                                                    overflowY: "scroll"
                                                }
                                            }>
                                            {loading ? <CSpinner color='info' /> : <ul className="nav nav-tabs flex-column p-2"
                                                style={
                                                    { borderBottom: "none" }
                                                }>
                                                {
                                                    loading ? <CSpinner color='info' /> : chatList?.length > 0 ? chatList?.map((data, index) => {
                                                        if (data._id === "") { } else {
                                                            if (data.isGroupChat === false) {
                                                                let Noduplicates = [];
                                                                data?.users?.filter((user) => (user._id !== id) ? Noduplicates.push(user) : "")
                                                                return <li className="nav-item"
                                                                    key={index}>
                                                                    <NavLink className="nav-link nav-light rounded-3 my-link"
                                                                        style={NavStyle}
                                                                        exact
                                                                        activeclassname="active"
                                                                        to={
                                                                            `/messagesmob/${chat}/` + id + "/" + data._id
                                                                        }>
                                                                        {
                                                                            Noduplicates[0]?.userAvatar?.cdnlocation !== "" ? (
                                                                                <>
                                                                                    <img src={
                                                                                        strings.MEDIAPATH + Noduplicates[0]?.userAvatar?.cdnlocation
                                                                                    }
                                                                                        style={
                                                                                            {
                                                                                                width: "50px",
                                                                                                height: "50px"
                                                                                            }
                                                                                        }
                                                                                        className='rounded-circle'
                                                                                        alt="" />
                                                                                    &nbsp;&nbsp; {
                                                                                        (Noduplicates[0]?.first_Name + " " + Noduplicates[0]?.last_Name).length > 15 ? ((Noduplicates[0]?.first_Name + " " + Noduplicates[0]?.last_Name).slice(0, 15) + "...") : ((Noduplicates[0]?.first_Name + " " + Noduplicates[0]?.last_Name))
                                                                                    } </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src="https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                                                        style={
                                                                                            {
                                                                                                width: "50px",
                                                                                                height: "50px"
                                                                                            }
                                                                                        }
                                                                                        className='rounded-circle'
                                                                                        alt="" />
                                                                                    &nbsp;&nbsp; {
                                                                                        (Noduplicates[0].first_Name + " " + Noduplicates[0].last_Name).length > 15 ? ((Noduplicates[0].first_Name + " " + Noduplicates[0].last_Name).slice(0, 15) + "...") : ((Noduplicates[0].first_Name + " " + Noduplicates[0].last_Name))
                                                                                    } </>
                                                                            )
                                                                        } </NavLink>
                                                                </li>
                                                            }
                                                            if (data.isGroupChat === true) {
                                                                return <li className="nav-item"
                                                                    key={index}>
                                                                    <NavLink className="nav-link nav-light rounded-3 my-link"
                                                                        style={NavStyle}
                                                                        exact
                                                                        activeclassname="active"
                                                                        to={
                                                                            `/messagesmob/${chat}/` + id + "/" + data._id
                                                                        }>
                                                                        {
                                                                            data?.chatLogo.length === 0 ? (
                                                                                <>
                                                                                    <img src="https://th.bing.com/th?q=People+Group+Flat+Icon&w=120&h=120&c=1&rs=1&qlt=90&cb=1&dpr=1.3&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247"
                                                                                        style={
                                                                                            {
                                                                                                width: "50px",
                                                                                                height: "50px"
                                                                                            }
                                                                                        }
                                                                                        className='rounded-circle'
                                                                                        alt="image2" />
                                                                                    &nbsp;&nbsp; {
                                                                                        data.chatName
                                                                                    } </>
                                                                            ) : (
                                                                                <>
                                                                                    <img src={
                                                                                        strings.CHATMEADIA + data?.chatLogo[0]?.path
                                                                                    }
                                                                                        style={
                                                                                            {
                                                                                                width: "50px",
                                                                                                height: "50px"
                                                                                            }
                                                                                        }
                                                                                        className='rounded-circle'
                                                                                        alt="" />
                                                                                    &nbsp;&nbsp; {
                                                                                        data.chatName
                                                                                    } </>
                                                                            )
                                                                        } </NavLink>
                                                                </li>
                                                            }
                                                        }
                                                    }) : <div style={
                                                        {
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            marginTop: "34vh",
                                                            marginBottom: "34vh"
                                                        }
                                                    }>No chats found</div>
                                                } </ul>}
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-xl-8 d-none d-lg-block">
                                        <div className='rounded-4 p-2'
                                            style={
                                                {
                                                    background: "white",
                                                    overflowY: "scroll",
                                                    height: "70vh"
                                                }
                                            }>
                                            <Outlet />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='text-center mt-5'>
                                <CSpinner color='info' />
                            </div>
                        </>
                    )
                } </div>)}
        </>


    )
}

export default ChatList;
