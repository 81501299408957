import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS } from "./actionTypes";

const initialState = {
    isAuth: localStorage.getItem("isAuth") || false,
    isError: false,
    isLoading: false,
    token: localStorage.getItem("token") || "",
    _id: localStorage.getItem("_id") || "",
    email: localStorage.getItem("email") || "",
    profile: localStorage.getItem("profile") || "",
    userName: localStorage.getItem("userName") || "",
    number: localStorage.getItem("number") || ""

}

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case LOGIN_REQUEST:
            return { ...state, isLoading: true }
        case LOGIN_SUCCESS:
            console.log("payload", payload)
            localStorage.setItem("isAuth", true)
            localStorage.setItem("token", payload.token);
            localStorage.setItem("_id", payload.admin._id);
            localStorage.setItem("email", payload.admin.email);
            localStorage.setItem("profile", payload.admin.profile);
            localStorage.setItem("userName", payload.admin.userName);
            localStorage.setItem("number", payload.admin.phoneNumber);
            return {
                ...state,
                isAuth: true,
                isLoading: false,
                token: payload.token,
                _id: payload.admin._id,
                email: payload.admin.email,
                profile: payload.admin.profile,
                userName: payload.admin.userName,
                number: payload.admin.phoneNumber
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}