import React, { useEffect, useState } from "react";
// import "./login.css";
import { useNavigate, Link, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import swal from "sweetalert";
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
    CFormLabel,
    CFormFeedback,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import logo from "../../Image/logo.png";
import { forgotPass, resetPass, checkAdminResetKey } from "../Helper/loginapi";

const ResetPassword = () => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [cpassword, setCPassword] = useState("");
    const [CpasswordErr, setCPasswordErr] = useState();
    const [checkKey, setCheckKey] = useState(false);
    const navigate = useNavigate();

    const { key } = useParams()

    // checking admin reset key
    useEffect(() => {
        const payloadData = {
            key
        }
        checkAdminResetKey(payloadData)
            .then((res) => {
                if (res.err === 200) {
                    setCheckKey(true)
                }
            }).catch((error) => {
                console.log(error)
            })
    }, [])

// reset password api

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            let payloadData = {
                password: cpassword,
            };
            resetPass(payloadData, key)
                .then(
                    async (res) => {
                        console.log(res)
                        if (res.err === 200) {
                            swal("Success", res.msg, "success").then((ok) => {
                                if (ok) {
                                    navigate("/")
                                }
                            })
                        } else {
                            swal("Warning", res.msg, 'warning');
                        }
                    }, (err) => {
                        swal("Error", "Server Error", 'error');
                    }
                )
                .catch();
        }
        setValidated(true);
    };

    return (

        <div className="loginBG min-vh-100 d-flex flex-row align-items-center">
            {
                checkKey ? (
                    <CContainer CContainer  >
                        <CRow className="justify-content-center">
                            <CCol md={4}>
                                <CCardGroup>
                                    <CCard className="p-1 bg-dark text-white rounded-4" style={{ boxShadow: "rgba(0, 0, 0, 0.3) 10px 10px 10px" }}>
                                        <CCardBody>
                                            <CForm
                                                noValidate
                                                validated={validated}
                                                onSubmit={handleSubmit}
                                            >
                                                <div className="row  rounded-4 mb-1 ">
                                                    <div className="col-3"></div>
                                                    <div className="col-6 text-center  rounded-4   p-2">
                                                        {/* <img src={logo} alt="" style={{ height: "30px" }} />  */}
                                                        &nbsp; <h5 className="text-white">Indian's Abroad</h5>
                                                    </div>
                                                    <div className="col-3"></div>
                                                </div>
                                                <h5>Reset Password</h5>
                                                <CInputGroup className="mb-3 mt-3">
                                                    <CInputGroupText>
                                                        <CIcon icon={cilUser} />
                                                    </CInputGroupText>
                                                    <CFormInput
                                                        placeholder="Enter Password"
                                                        type="password"
                                                        name="email"
                                                        onChange={(e) => {
                                                            setPassword(e.target.value); setValidated(false)
                                                        }}
                                                        required
                                                    />
                                                    <CFormFeedback invalid>
                                                        Please enter password.
                                                    </CFormFeedback>
                                                </CInputGroup>
                                                <CInputGroup className="mb-3 mt-3">
                                                    <CInputGroupText>
                                                        <CIcon icon={cilUser} />
                                                    </CInputGroupText>
                                                    <CFormInput
                                                        placeholder="Confirm Password"
                                                        type="password"
                                                        name="cpassword"
                                                        onChange={(e) => {
                                                            setCPassword(e.target.value); setValidated(false);
                                                        }}
                                                        required
                                                    />
                                                    <CFormFeedback invalid>Please enter confirm password.</CFormFeedback>
                                                </CInputGroup>


                                                <CRow>
                                                    <CCol xs={6}>
                                                        <CButton color="primary" className="px-4 mb-3" type="submit"
                                                            disabled={loading}>
                                                            {loading ? "Wait.." : "Enter"}
                                                        </CButton>
                                                    </CCol>

                                                </CRow>
                                            </CForm>
                                        </CCardBody>
                                    </CCard>
                                </CCardGroup>
                            </CCol>
                        </CRow>
                    </CContainer >
                ) :
                    (
                        <div style={{ margin: "auto", textAlign: "center" }}>
                            <h1>Opps!</h1>
                            <p>Invalid url</p>
                        </div>
                    )
            }


        </div>
    )

}

export default ResetPassword;