import React, { useState, useEffect } from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormLabel,
    CRow,
    CSpinner
} from "@coreui/react";
import * as icon from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import { cilFolderOpen } from "@coreui/icons";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { getAllTransactions } from "../Helper/Index.Api";
import AppTitle from "../AppTitle";
import { useParams } from 'react-router-dom';

const EventTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [listLoading, setListLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        getAllTransactions(id).then((res) => {
            console.log(res.data);
            setTransactions(res.data);
            setListLoading(false);
        }).catch(err => {
            console.log(err);
            setListLoading(false);
        });
    }, []);

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: "#5470af",
                color: "white"
            }
        },
        cells: {
            style: {
                borderRight: "1px solid #ddd",
                borderLeft: "1px solid #ddd"
            }
        }
    };

    const columns = [
        {
            name: 'Sr. No.',
            selector: (row, index) => index + 1,
            width: "70px"
        },
        {
            name: 'Attendee Id',
            selector: row => (row?.attendee_id),
        }, 
        {
            name: 'Amount',
            selector: row => (row?.amount),
        },
        {
            name: 'Currency',
            selector: row => (row?.currency),
        },
        {
            name: 'Payment Status',
            selector: row => (row?.payment_status),
        },
        {
            name: "Created Date",
            selector: row => row?.createdAt ,
        }
    ];

    return (
        <>
            <AppTitle title={"Transactions"} showbtn={true} path="/events" />
            <div className="px-lg-5 mt-lg-5 px-2 mt-2">
                <CCard>
                    <CCardHeader>
                        <strong>Transactions</strong>
                    </CCardHeader>
                    <CCardBody>
                        {listLoading ? (
                            <div className="d-flex justify-content-center">
                                <CSpinner style={{ marginTop: "40px" }} color="info" />
                            </div>
                        ) : (
                            <DataTable
                                columns={columns}
                                data={transactions}
                                customStyles={customStyles}
                                highlightOnHover
                            />
                        )}
                    </CCardBody>
                </CCard>
            </div>
        </>
    );
};

export default EventTransactions;