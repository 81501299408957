import React from 'react'
import {
    CContainer,
    CHeader
} from '@coreui/react'
import { useNavigate } from 'react-router-dom';
import strings from './strings';



const AppTitle = ({ title, path, showbtn = false, addbtn = false, addpath, name, image, email }) => {
    const navigate = useNavigate();

    return (
        <CHeader style={{ backgroundColor: "white" }}>
            <CContainer fluid>
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <h5 color='gray'>{title}<span> </span></h5>
                    {image && <img src={
                        image !== "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7" ? strings?.MEDIAPATH + image : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                    }
                        style={
                            {
                                width: "55px",
                                height: "55px",
                                border: "4px solid white"
                            }
                        }
                        className='rounded-circle mx-xl-2'
                        alt="" />}
                    {name && <div>
                        <h5 color='gray'>{name ? `${name}` : ""}</h5>
                        <h5 className='d-none d-md-flex' style={{ fontSize: "14px" }}>{email}</h5>
                    </div>}
                </div>
                {showbtn && <button className='border border-none bg-danger text-white rounded px-3' onClick={() => navigate(`${path}`)}>Back</button>}
                {addbtn && <button className='border border-none  text-white rounded px-4 py-1' style={{ backgroundColor: "#5470af" }} onClick={() => navigate(`${addpath}`)} >Add Plan</button>}
            </CContainer>
        </CHeader>
    )
}

export default AppTitle
