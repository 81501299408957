import React, { useState, useEffect } from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CSpinner
} from "@coreui/react";
import DataTable from "react-data-table-component";
import { sendPushNotificationById, listPushNotification } from "../Helper/Index.Api"; 
import swal from "sweetalert";

const ListNotification = () => {
    const [notifications, setNotifications] = useState([]);
    const [listLoading, setListLoading] = useState(true);

    useEffect(() => {
        // Fetch all notifications
        listPushNotification().then((res) => {
            console.log(res.data);
            setNotifications(res.data);
            setListLoading(false);
        }).catch(err => {
            console.log(err);
            setListLoading(false);
        });
    }, []);

    const handleSend = async (id) => {
        try {
            console.log(id);
            let payload = { notificationId: id };
            const response = await sendPushNotificationById(payload);
            swal('Success', response?.data?.message || "Notification sent successfully!", 'success');
        } catch (error) {
            console.error("Error sending notification:", error);
            const errorMessage = error.response?.data?.message || "An error occurred while sending the notification";
            swal('Error', errorMessage, 'error');
        } finally {
            //setLoading(false); // Set loading to false after API call
        }
    };
    

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: "#5470af",
                color: "white"
            }
        },
        cells: {
            style: {
                borderRight: "1px solid #ddd",
                borderLeft: "1px solid #ddd"
            }
        }
    };

    const buttonStyles = {
        color: "white",
        fontSize: "0.8rem",
        padding: "0.25rem 0.5rem"
    };

    const columns = [
        {
            name: 'Title',
            selector: row => row?.title,
        },
        {
            name: 'Type',
            selector: row => row?.type,
        },
        {
            name: 'Message',
            selector: row => row?.message,
        },
        {
            name: 'Email',
            selector: row => row?.email,
        },
        {
            name: 'Status',
            selector: row => row?.status,
        },
        {
            name: 'Actions',
            selector: row => (
                <CButton
                    color="primary"
                    onClick={() => handleSend(row._id)}
                    className="p-1"
                    style={buttonStyles}
                >
                    Send
                </CButton>
            ),
            width: "150px"
        },
    ];

    return (
        <div className="px-lg-5 mt-lg-5 px-2 mt-2">
            <CCard>
                <CCardHeader>
                    <strong>List of Notifications</strong>
                </CCardHeader>
                <CCardBody>
                    {listLoading ? (
                        <div className="d-flex justify-content-center">
                            <CSpinner style={{ marginTop: "40px" }} color="info" />
                        </div>
                    ) : (
                        <DataTable
                            columns={columns}
                            data={notifications}
                            customStyles={customStyles}
                            highlightOnHover
                        />
                    )}
                </CCardBody>
            </CCard>
        </div>
    );
};

export default ListNotification;