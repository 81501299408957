import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CFormFeedback,
  CFormLabel,
  CFormSelect,
} from "@coreui/react";
import { Link } from "react-router-dom";
import { sendPushNotification } from "../Helper/Index.Api";
import swal from "sweetalert";

const CreateNotification = () => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, settype] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState(null);
  const [email, setEmail] = useState(""); 

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
  
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const formData = new FormData();
      formData.append("type", type);
      formData.append("title", title);
      formData.append("message", message);
      if (image) {
        formData.append("image", image);
      }
      if (type === "specificUser") {
        formData.append("email", email);
      }
  
      const data = {
        type,
        title,
        message,
        email: type === "specificUser" ? email : null,
        image,
      };
  
      try {
        setLoading(true);
        const response = await sendPushNotification(data);
  
        if (response.err && response.err !== 200) {
           const errorMessage = response.message || "An error occurred";
          swal('Error', errorMessage, 'error');
        } else {
            swal('Success', response.message || "Notification sent successfully!", 'success');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || "An error occurred";
        swal('Error', errorMessage, 'error');
      } finally {
        setLoading(false);
      }
    }
    setValidated(true);
  };
 

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <CCard className="p-4">
              <CCardBody>
                <CForm noValidate validated={validated} onSubmit={handleSubmit}>
                  <h3 className="mb-4">Create Notification</h3>

                  {/* User Type Dropdown */}
                  <CFormLabel>Select User Type</CFormLabel>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>User Type</CInputGroupText>
                    <CFormSelect
                      required
                      value={type}
                      onChange={(e) => settype(e.target.value)}
                    >
                      <option value="">Choose...</option>
                      <option value="loggedInUser">Logged in user</option>
                      <option value="logoutUsers">Logout users</option>
                      <option value="specificUser">Specific user</option>
                    </CFormSelect>
                    <CFormFeedback invalid>
                      Please select a user type.
                    </CFormFeedback>
                  </CInputGroup>

                  {/* Email field, shown only if 'Specific user' is selected */}
                  {type === "specificUser" && (
                    <>
                      <CFormLabel>Email</CFormLabel>
                      <CInputGroup className="mb-3">
                        <CFormInput
                          type="email"
                          placeholder="Enter specific user's email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <CFormFeedback invalid>
                          Please provide a valid email.
                        </CFormFeedback>
                      </CInputGroup>
                    </>
                  )}

                  {/* Title */}
                  <CFormLabel>Title</CFormLabel>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      type="text"
                      placeholder="Enter title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                    <CFormFeedback invalid>
                      Please provide a title.
                    </CFormFeedback>
                  </CInputGroup>

                  {/* message */}
                  <CFormLabel>Message</CFormLabel>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      as="textarea"
                      rows="3"
                      placeholder="Enter message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    />
                    <CFormFeedback invalid>
                      Please provide a message.
                    </CFormFeedback>
                  </CInputGroup>

                  {/* Image Upload */}
                  <CFormLabel>Upload Image</CFormLabel>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      type="file"
                      accept="image/*"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </CInputGroup>

                  {/* Submit Button */}
                  <CRow>
                    <CCol xs={6}>
                      <CButton color="primary" type="submit" disabled={loading}>
                        {loading ? "Sending..." : "Send"}
                      </CButton>
                      <Link to="/listnotification">
                        <CButton color="secondary">
                          View Notifications
                        </CButton>
                      </Link>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};
export default CreateNotification;