import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CContainer,
    CRow,
    CTable,
    CTableBody,
    CTableHead,
    CTableHeaderCell,
    CTableRow
} from '@coreui/react'
import React, {useEffect, useState} from 'react'
import SubscriptionListComp from './SubscriptionListComp'
import {getSubsrciptionPlans} from '../Helper/Index.Api'
import AppTitle from '../AppTitle'

export const SubscriptionPlanList = () => {
    const [plans, setPlans] = useState([])


    // getting subscription plan list 
    useEffect(() => {
        getSubsrciptionPlans().then((res) => setPlans(res.subsrciption_plans)).catch((err) => console.log(err))
    }, [])

    return (
        <>
            <AppTitle title={"Plan Configuration"}
                addbtn={true} addpath={"/addsubscriptionplan"}/>
            <div className='container-fluid px-lg-5 mt-lg-5 px-2 mt-2'>
                <CCard>
                    <CCardHeader>Subscription Plans
                    </CCardHeader>
                    <CCardBody>
                        <CTable responsive={"sm"}>
                            <CTableHead>
                                <CTableRow>
                                    <CTableHeaderCell scope="col">Title</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Duration</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Regular Price</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Sell Price</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Discount</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody> {
                                plans.length > 0 && plans ?. map((el, i) => {
                                    return <SubscriptionListComp index={i}
                                        id={
                                            el._id
                                        }
                                        key={
                                            el._id
                                        }
                                        title={
                                            el.title
                                        }
                                        description={
                                            el.description
                                        }
                                        duration={
                                            el.duration
                                        }
                                        period={
                                            el.period
                                        }
                                        sell_price={
                                            el.sell_price
                                        }
                                        regular_price={
                                            el.regular_price
                                        }
                                        isActive={
                                            el.isActive
                                        }
                                        discount={el.discount}/>
                                })
                            } </CTableBody>
                        </CTable>
                    </CCardBody>
                </CCard>
            </div>
        </>
    )
}
