import React, { useEffect, useState } from "react";
// import "./login.css";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
    CFormLabel,
    CFormFeedback,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import logo from "../../Image/logo.png";
import { forgotPass, resetPass } from "../Helper/loginapi";
import { isTokenExpired, userForgotPass } from "../Helper/Index.Api";

const ResetPassForUser = () => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passCode, setPassCode] = useState("");
    const [cpassword, setCPassword] = useState("");
    const [CpasswordErr, setCPasswordErr] = useState();
    const [message, setMessage] = useState();
    const [errMessage, setErrMessage] = useState(false);
    const navigate = useNavigate();
    const { key } = useParams()

    // checking token validation
    useEffect(() => {
        isTokenExpired(key).then((data) => {
            if (data.err === 300) {
                setErrMessage(true)
            } else {
                console.log(data.msg)
            }
        })
    }, [])

    // reset password for user
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (passCode !== cpassword) {
                swal("Warning", "Password and Confirm password does not match", "warning")
            } else {
                userForgotPass(key, { passCode })
                    .then(
                        async (res) => {
                            console.log(res)
                            if (res.err === 200) {
                                swal("Success", res.msg, "success").then((ok) => {
                                    if (ok) {
                                        setMessage(true)
                                    }
                                })
                            } else {
                                swal("Warning", res.msg, 'warning');
                            }
                        }, (err) => {
                            swal("Error", "Server Error", 'error');
                        }
                    )
                    .catch();
            }
        }

        setValidated(true);
    };

    return (
        <div className="loginBG min-vh-100 d-flex flex-row align-items-center">

            <CContainer>
                <CRow className="justify-content-center">
                    {
                        errMessage ? (<>
                            <CCol md={4}>
                                <CCardGroup>
                                    <CCard className="p-1 bg-dark text-center text-white rounded-4" style={{ boxShadow: "rgba(0, 0, 0, 0.3) 10px 10px 10px" }}>
                                        <CCardBody>
                                            <h5>Invalid Link</h5>
                                        </CCardBody>
                                    </CCard>
                                </CCardGroup>
                            </CCol>
                        </>) : (<>
                            {
                                message ? (<>
                                    <CCol md={4}>
                                        <CCardGroup>
                                            <CCard className="p-1 bg-dark text-center text-white rounded-4" style={{ boxShadow: "rgba(0, 0, 0, 0.3) 10px 10px 10px" }}>
                                                <CCardBody>
                                                    <h5>Password Reset Successfully</h5>
                                                </CCardBody>
                                            </CCard>
                                        </CCardGroup>
                                    </CCol>
                                </>) : (<>
                                    <CCol md={4}>
                                        <CCardGroup>
                                            <CCard className="p-1 bg-dark text-white rounded-4" style={{ boxShadow: "rgba(0, 0, 0, 0.3) 10px 10px 10px" }}>
                                                <CCardBody>
                                                    <CForm
                                                        noValidate
                                                        validated={validated}
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <div className="row  rounded-4 mb-1 ">
                                                            <div className="col-3"></div>
                                                            <div className="col-6 text-center  rounded-4   p-2">
                                                                {/* <img src={logo} alt="" style={{ height: "30px" }} />  */}
                                                                &nbsp; <h5 className="text-white">India's Abroad</h5>
                                                            </div>
                                                            <div className="col-3"></div>
                                                        </div>
                                                        <h5>Reset Password</h5>
                                                        <CInputGroup className="mb-3 mt-3">
                                                            <CInputGroupText>
                                                                <CIcon icon={cilUser} />
                                                            </CInputGroupText>
                                                            <CFormInput
                                                                placeholder="Enter Password"
                                                                type="password"
                                                                name="email"
                                                                onChange={(e) => {
                                                                    setPassCode(e.target.value); setValidated(false)
                                                                }}
                                                                required
                                                            />
                                                            <CFormFeedback invalid>
                                                                Please enter password.
                                                            </CFormFeedback>
                                                        </CInputGroup>
                                                        <CInputGroup className="mb-3 mt-3">
                                                            <CInputGroupText>
                                                                <CIcon icon={cilUser} />
                                                            </CInputGroupText>
                                                            <CFormInput
                                                                placeholder="Confirm Password"
                                                                type="password"
                                                                name="cpassword"
                                                                onChange={(e) => {
                                                                    setCPassword(e.target.value); setValidated(false);
                                                                }}
                                                                required
                                                            />
                                                            <CFormFeedback invalid>Please enter confirm password.</CFormFeedback>
                                                        </CInputGroup>
                                                        <CRow>
                                                            <CCol xs={6}>
                                                                <CButton color="primary" className="px-4 mb-3" type="submit"
                                                                    disabled={loading}>
                                                                    {loading ? "Wait.." : "Enter"}
                                                                </CButton>
                                                            </CCol>
                                                        </CRow>
                                                    </CForm>
                                                </CCardBody>
                                            </CCard>
                                        </CCardGroup>
                                    </CCol>
                                </>)
                            }
                        </>)
                    }
                </CRow>
            </CContainer>
        </div>
    )
}
export default ResetPassForUser;