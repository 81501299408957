import React, {useEffect, useState} from 'react'
import * as Yup from "yup";
import {useFormik} from 'formik'
import swal from 'sweetalert'
import AppTitle from '../AppTitle'
import {FormLabel} from 'react-bootstrap'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormInput,
    CFormSelect
} from '@coreui/react'
import {useNavigate} from 'react-router-dom';
import {CreateSubscriptionPlan} from '../Helper/Index.Api';

const formSchema = Yup.object({
    title: Yup.string().required("Title is required").min(2, "Minimum 2 characters required.").max(50, "Maximum 50 characters are allowed."),
    duration: Yup.number().required("Duarion is required.").moreThan(0, "Please select plan duration."),
    sell_price: Yup.number().required("Sell Price is required").moreThan(0, "Sell Price should be greater than 0").lessThan(Yup.ref("regular_price"), "Sell price must be less than or equal to regular price"),
    regular_price: Yup.number().required("Regular Price is required.").moreThan(0, "Regular Price should be greater than 0")
})

const AddSubscriptionPlan = () => {
    const navigate = useNavigate();


    // adding subscription plan
    const onSubmit = async (values) => {
        try {
            const payload = {
                _id: values._id,
                title: values.title,
                duration: values.duration,
                sell_price: values.sell_price,
                regular_price: values.regular_price
            }
            CreateSubscriptionPlan(payload).then((res) => {
                console.log(res.err)
                if (res.err === 200) {

                    swal("create", "Subscription plan Created!!!", "success").then((res) => {
                        navigate("/subscriptionplans")
                    })
                } else if (res.err === 300) {
                    swal("exist", "Plan Already exists!!!", "info");
                } else {
                    swal("create", "Subscription plan failed!!!", "error");
                }
            })
        } catch (err) {
            console.log(err)
        }


    }

    const formik = useFormik({
        initialValues: {
            title: "",
            duration: 0,
            sell_price: 0
        },
        validateOnBlur: true,
        validationSchema: formSchema,
        enableReinitialize: true,
        onSubmit
    });


    return (
        <>
            <AppTitle title={"Add Subscription Plan"}
                showbtn={true}
                path={"/subscriptionplans"}/>
            <div className='Container-fluid d-flex justify-content-center mt-5'>
                <CCard className='col-12 col-lg-5 col-md-6 pb-2'>
                    <CCardHeader>Add Subscription Plan</CCardHeader>
                    <CCardBody>
                        <CCol xm={12}>
                            <form onSubmit={
                                formik.handleSubmit
                            }>
                                <FormLabel>Title</FormLabel>
                                <CFormInput placeholder="Title" name="title"
                                    onChange={
                                        formik.handleChange
                                    }
                                    onBlur={
                                        formik.handleBlur
                                    }
                                    value={
                                        formik.values.title
                                    }
                                    aria-label="Title"
                                    aria-describedby="basic-addon1"/> {
                                formik.touched.title && formik.errors.title ? (
                                    <div className="text-danger mt-2">
                                        {
                                        formik.errors.title
                                    }</div>
                                ) : null
                            }
                                <FormLabel className='mt-2'>Duration</FormLabel>
                                <CFormSelect size="md"
                                    onChange={
                                        formik.handleChange
                                    }
                                    onBlur={
                                        formik.handleBlur
                                    }
                                    value={
                                        formik.values.duration
                                    }
                                    name='duration'
                                    type='number'
                                    placeholder='Duration'
                                    aria-label="Select Duration">
                                    <option value={0}>Select Duration in Months</option>
                                    <option value={1}>1 month</option>
                                    <option value={2}>2 months</option>
                                    <option value={3}>3 months</option>
                                    <option value={4}>4 months</option>
                                    <option value={5}>5 months</option>
                                    <option value={6}>6 months</option>
                                    <option value={7}>7 months</option>
                                    <option value={8}>8 months</option>
                                    <option value={9}>9 months</option>
                                    <option value={10}>10 months</option>
                                    <option value={11}>11 months</option>
                                    <option value={12}>12 months</option>
                                </CFormSelect>
                                {
                                formik.touched.duration && formik.errors.duration ? (
                                    <div className="text-danger mt-2">
                                        {
                                        formik.errors.duration
                                    }</div>
                                ) : null
                            }
                                <FormLabel className='mt-2'>Regular Price</FormLabel>
                                <CFormInput placeholder="Regular Price" name="regular_price" aria-label="Regular Price"
                                    value={
                                        formik.values.regular_price
                                    }
                                    onChange={
                                        formik.handleChange
                                    }
                                    onBlur={
                                        formik.handleBlur
                                    }
                                    aria-describedby="basic-addon1"
                                    min={0}
                                    type='number'/> {
                                formik.touched.regular_price && formik.errors.regular_price ? (
                                    <div className="text-danger mt-2">
                                        {
                                        formik.errors.regular_price
                                    }</div>
                                ) : null
                            }
                                <FormLabel className='mt-2'>Sell Price</FormLabel>
                                <CFormInput placeholder="Sell Price" name="sell_price" aria-label="Sell Price"
                                    value={
                                        formik.values.sell_price
                                    }
                                    onChange={
                                        formik.handleChange
                                    }
                                    onBlur={
                                        formik.handleBlur
                                    }
                                    aria-describedby="basic-addon1"
                                    min={0}
                                    type='number'/> {
                                formik.touched.sell_price && formik.errors.sell_price ? (
                                    <div className="text-danger mt-2">
                                        {
                                        formik.errors.sell_price
                                    }</div>
                                ) : null
                            }
                                <div className='d-flex mt-4 gap-4'>
                                    <button type='submit'
                                        style={
                                            {
                                                backgroundColor: "#5470af",
                                                border: "none"
                                            }
                                        }
                                        className='text-white rounded px-3'>Submit</button>
                                </div>
                            </form>

                        </CCol>
                    </CCardBody>
                </CCard>
            </div>
        </>
    )
}

export default AddSubscriptionPlan
