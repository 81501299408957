import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { messageList } from '../Helper/Index.Api';
import strings from '../strings';
import moment from 'moment';
import { CSpinner } from '@coreui/react';
import VideoImageThumbnail from 'react-video-thumbnail-image';
import AppTitle from '../AppTitle';
import CIcon from '@coreui/icons-react';
import { cilArrowLeft } from "@coreui/icons";
import "./message.css";
import { MdDownloadForOffline } from 'react-icons/md';
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs';
const MessagesMob = () => {
    let { chat, id, cid } = useParams();
    const [listLoading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [imageLoading, setImageLoading] = useState(true);
    const navigate = useNavigate();

    const handleImageLoad = () => {
        setImageLoading(false);
    };

    // Function to handle image error
    const handleImageError = () => {
        setImageLoading(false);
        console.log(false)
    };

    // get all messages
    useEffect(() => {
        let data = {
            search: "",
            chatId: cid,
            sortBy: "",
            currUser: id,
            page: "3"
        }

        setLoading(true);
        messageList(data).then((res) => {
            if (res.err === 200) {
                setLoading(false);
                setMessages(res.data.reverse());
            }
        }).catch((err) => {
            if (err) {
                setLoading(false);
            }
        })
    }, [cid]);


    const messagesEndRef = useRef(null)

    // scroll at bottom when its load all the messages
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
    }

    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    // changing ui according to the different types of message content
    function content(msg, content_type) {
        if (content_type === "text/plain") {
            return msg.content
        } else if ((content_type).slice(0, 5) === "image") {
            if (msg.content === "") {
                return <div>

                    <img src={
                        strings.MEDIAPATH + msg?.file[0].cdnlocation
                    }
                        style={
                            {
                                width: "150px",
                                display: imageLoading ? "none" : "block"
                            }
                        }
                        alt=""
                        onLoad={handleImageLoad}
                        onError={handleImageError} />
                </div>
            } else {
                return <div className='text-start'>
                    <div className='border border-white'>
                        <img src={
                            strings.MEDIAPATH + msg?.file[0].cdnlocation
                        }
                            style={
                                { width: "150px" }
                            }
                            alt="" />
                    </div>
                    <div className=''>
                        <small>{
                            msg.content
                        }</small>
                    </div>

                </div>
            }
        } else if ((content_type).slice(0, 5) === "video") {
            if (msg.content === "") {
                return <>
                    <div className='text-start'>
                        {/* <VideoImageThumbnail
                            thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                            videoUrl={strings.API_ROUTE + msg?.file[0].path}
                        /> */}
                        {/* <img src={strings.API_ROUTE + msg?.file[0].path} alt="" /> */}
                        <video controls width="150" height="200">
                            <source src={
                                strings.MEDIAPATH + msg?.file[0].cdnlocation
                            }
                                width="150"
                                height="200"
                                type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        {/* <video src={} type="video/mp4" style={{ width: "150px" }} controls></video> */} </div>

                </>
            } else {
                return <div className='text-start'>
                    {/* <VideoImageThumbnail
                        videoUrl={strings.API_ROUTE + msg?.file[0].path}
                    /> */}
                    <video controls width="150" height="200">
                        <source src={
                            strings.MEDIAPATH + msg?.file[0].cdnlocation
                        }
                            width="150"
                            height="200"
                            type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <br />
                    <small className=''>
                        {
                            msg.content
                        }</small>
                </div>
            }

        } else if ((content_type).slice(0, 5) === "audio") {
            if (msg.content === "") {
                return <audio controls className="custom_audio">
                    <source src={
                        strings.MEDIAPATH + msg?.file[0].cdnlocation
                    }
                        type="audio/ogg" />
                    <source src="horse.mp3" type="audio/mpeg" />
                    Your browser does not support the audio tag.
                </audio>
            } else {
                return <div>
                    <audio controls className='custom_audio'>
                        <source src={
                            strings.MEDIAPATH + msg?.file[0].cdnlocation
                        }
                            type="audio/ogg" />
                        <source src="horse.mp3" type="audio/mpeg" />
                        Your browser does not support the audio tag.
                    </audio>
                    <br /> {
                        msg.content
                    } </div>
            }
        }
        else {
            return < a href={`${strings.MEDIAPATH + msg.file[0].key}`} target="_blank" download style={{ display: "flex", justifyContent: "space-between", textDecoration: "none" }
            }>
                <p><BsFillFileEarmarkPdfFill fontSize={"50px"} color='red' /></p><span style={{ marginTop: "40px" }}><MdDownloadForOffline fontSize={"20px"} color='black' /></span>
            </a >
        }
    }

    function NewDate(date) {
        let currentDate = new Date();
        currentDate.setHours(12, 0, 0, 0);
        if (currentDate > date) {
            return true;
        }
        else {
            return false;
        }

    }

    return (
        <>
            <div className='position-fixed m-2 p-2 p- d-lg-none d-flex rounded-5'
                style={
                    {
                        zIndex: 1,
                        backgroundColor: "#5470af"
                    }
                }
                onClick={
                    () => navigate(`/messages/${chat}/${id}`)
                }>
                {/* <AppTitle title={"Chat"}
                    showbtn={true}
                    path={
                        `/messages/${chat}/${id}`
                    }/> */}
                <CIcon icon={cilArrowLeft}
                    style={
                        { color: "white" }
                    } />
            </div>
            <div className="container d-flex d-lg-none">
                <div className="row"
                    style={
                        { width: "100%" }
                    }>
                    {
                        listLoading ? (
                            <>
                                <div className="col-12 text-center mt-5">
                                    <CSpinner color="info" />
                                </div>
                            </>
                        ) : (
                            <> {
                                messages?.length > 0 ? (
                                    <> {
                                        messages?.map((msg, index) => {
                                            if (msg?.createdBy?._id !== id) {
                                                return <div className="col-lg-12 text-start d-flex justify-content-start"
                                                    key={index}>
                                                    <div>
                                                        <img src={
                                                            msg?.createdBy?.userAvatar ? strings.MEDIAPATH + msg?.createdBy?.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                        }
                                                            className='rounded-circle me-3 mt-3'
                                                            style={
                                                                {
                                                                    width: "40px",
                                                                    height: "40px"
                                                                }
                                                            }
                                                            alt="" />
                                                    </div>
                                                    <div className='text-start mt-3'
                                                        style={
                                                            { maxWidth: "500px" }
                                                        }>
                                                        <p style={
                                                            { fontSize: "12px" }
                                                        }>
                                                            {
                                                                msg?.createdBy?.first_Name
                                                            }
                                                            {" "}
                                                            {
                                                                msg?.createdBy?.last_Name
                                                            }</p>
                                                        <div className=' px-2 py-2 rounded-4'
                                                            style={
                                                                {
                                                                    backgroundColor: "rgba(4, 117, 107, 0.1)",
                                                                    color: "#000000",
                                                                    // fontWeight: "bold",
                                                                    wordWrap: "break-word",
                                                                    marginTop: "-15px",
                                                                    fontSize: "14px"
                                                                }
                                                            }>
                                                            {
                                                                content(msg, msg.content_type)
                                                            } </div>
                                                        <small className='me-auto'
                                                            style={
                                                                { fontSize: "13px" }
                                                            }>
                                                            {NewDate(msg.createdAt) && moment(msg.createdAt).format("hh:mm A")}
                                                            {!NewDate(msg.createdAt) && moment(msg.createdAt).format('D MMM YY  h:mm A')}</small>
                                                    </div>
                                                </div>
                                            } else {
                                                return <div className="col-lg-12 text-end d-flex justify-content-end">
                                                    <div className='text-end mt-3'
                                                        style={
                                                            { maxWidth: "500px" }
                                                        }>
                                                        <div className='py-2 px-3 rounded-4'
                                                            style={
                                                                {
                                                                    color: "#000000",
                                                                    background: "#d1d7db",
                                                                    wordWrap: "break-word",
                                                                    // textAlign:"right",
                                                                    // textAlign:"center",
                                                                    // display:"flex",
                                                                    // justifyContent:"center",
                                                                    // alignItems:"center",
                                                                    fontSize: "14px"
                                                                }
                                                            }>
                                                            {
                                                                content(msg, msg.content_type)
                                                            } </div>
                                                        <small className='ms-auto'
                                                            style={
                                                                {
                                                                    fontSize: "13px",
                                                                    // marginRight: "-20px"
                                                                }
                                                            }>
                                                            {NewDate(msg.createdAt) && moment(msg.createdAt).format("hh:mm A")}
                                                            {!NewDate(msg.createdAt) && moment(msg.createdAt).format('D MMM YY  h:mm A')}
                                                            &nbsp; &nbsp; &nbsp;
                                                        </small>
                                                    </div>
                                                    <div>
                                                        <img src={
                                                            msg?.createdBy?.userAvatar ? strings.MEDIAPATH + msg?.createdBy?.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                        }
                                                            className='mt-3 ms-3 rounded-circle'
                                                            style={
                                                                {
                                                                    width: "40px",
                                                                    height: "40px"
                                                                }
                                                            }
                                                            alt="" />
                                                    </div>

                                                </div>
                                            }
                                        })
                                    } </>
                                ) : (
                                    <>
                                        <div className='text-center mt-5'>
                                            <h4 className='text-secondary'>No Messages Found</h4>
                                        </div>
                                    </>
                                )
                            } </>
                        )
                    }
                    <div ref={messagesEndRef} />
                </div>
            </div>

        </>

    )
}
export default MessagesMob;
