import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CSpinner
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetCommunityPagePosts } from '../Helper/Index.Api';
import strings from '../strings';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment/moment';
import "./community.css";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const CommunityPagePosts = ({ userPostData }) => {
    const { id } = useParams();
    const [showMenu, setShowMenu] = useState(false);

    // rendering media files
    const renderMediaFile = (file, index) => {
        // console.log("location:", file)
        if (file.metadata.contentType.slice(0, 5) == "image") {
            return (
                <img key={index}
                    src={
                        file.location
                    }
                    alt='User Posted Image'
                    className='posted-image'
                    style={
                        {
                            width: '100%',
                            height: '350px',
                            objectFit: "cover"
                        }
                    } />
            );
        } else if (file.metadata.contentType.slice(0, 5) == "video") {
            return (
                <video key={index}
                    src={
                         file.location
                    }
                    type={
                        file.type
                    }
                    controls
                    className='posted-video'
                    style={
                        {
                            width: '100%',
                            height: '350px',
                            objectFit: "cover"
                        }
                    }></video>
            );
        }
    };

    return (
        <CCol className='mt-3'>
            {
                userPostData ? (
                    <> {
                        userPostData.length > 0 ? (
                            <div className='row'>
                                {
                                    userPostData.map((data, index) => (
                                        <div key={index}
                                            className='col-lg-4 mb-4'>
                                            <div className='card'
                                                style={
                                                    {
                                                        backgroundColor: "white",
                                                        borderRadius: "5px 5px 0px 0px",
                                                    }
                                                }>
                                                <div className=' rounded-4 pt-3 ps-3 pe-3'
                                                    style={
                                                        { height: "50px" }
                                                    }>
                                                    <small className='text-secondary'>
                                                        {
                                                            moment.utc(data.createdAt).format("DD MMM YYYY")
                                                        }</small>&nbsp;&nbsp;
                                                    <small className='pt-0 text-secondary'
                                                        style={
                                                            { fontSize: "5px" }
                                                        }>
                                                        <i class="fa fa-circle " aria-hidden="true"></i>
                                                    </small>&nbsp;&nbsp;
                                                    <small className='text-secondary'>
                                                        {
                                                            data.shareType.charAt(0).toUpperCase() + data.shareType.slice(1)
                                                        }</small>
                                                    <br />
                                                </div>
                                                <div className=''
                                                    style={
                                                        { backgroundColor: "rgb(79 113 189 / 39%)" }
                                                    }>
                                                    {
                                                        data.mediaFiles.length > 0 ? (
                                                            <Slider {...settings}>
                                                                {
                                                                    data.mediaFiles.map((file, index) => (
                                                                        <div key={index} style={{ boxSizing: "contain" }}>
                                                                            {
                                                                                renderMediaFile(file, index)
                                                                            }</div>
                                                                    ))
                                                                } </Slider>
                                                        ) : (
                                                            < div className="position-relative"
                                                                style={
                                                                    {
                                                                        width: "100%",
                                                                        height: "355px"
                                                                    }
                                                                }>
                                                                {
                                                                    data.message.length > 350 ? <div className='position-absolute top-50 start-0 translate-middle-y p-2'>
                                                                        {
                                                                            data.message.slice(0, 350) + "..."
                                                                        } </div> : <div className='position-absolute top-50 start-0 translate-middle-y p-2'>
                                                                        {
                                                                            data.message
                                                                        } </div>
                                                                } </div>
                                                        )
                                                    } </div>
                                                <div className='p-3'
                                                    style={
                                                        {
                                                            height: "70px",
                                                            backgroundColor: "white",
                                                            marginTop: "-26px",
                                                            zIndex: "1"
                                                        }
                                                    }>
                                                    <i class="fa fa-heart text-danger" aria-hidden="true"></i>&nbsp;{
                                                        data?.likeCount
                                                    }&nbsp;&nbsp;
                                                    <i class="far fa-comment" aria-hidden="true"></i>&nbsp;{
                                                        data?.commentCount
                                                    }&nbsp;&nbsp;
                                                    <i class="fa fa-share" aria-hidden="true"></i>&nbsp;{
                                                        data?.shareCount
                                                    }&nbsp; {
                                                        data?.message?.length > 30 ? <p>{
                                                            data.message.slice(0, 30) + "..."
                                                        }</p> : <p>{
                                                            data.message
                                                        }</p>
                                                    } </div>
                                            </div>
                                        </div>
                                    ))
                                } </div>
                        ) : (
                            <CCard className='text-center'>
                                <CCardBody>No posts available</CCardBody>
                            </CCard>
                        )
                    } </>
                ) : (
                    <>
                        <div className='text-center mt-5'>
                            <CSpinner color='info' />
                        </div>
                    </>
                )
            } </CCol>
    );
};

export default CommunityPagePosts;
