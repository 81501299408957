import React, {useEffect, useState} from 'react'
import {getSystemUsers} from '../Helper/Index.Api';
import {CButton, CCard, CCardBody, CCardHeader} from '@coreui/react';
import DataTable from 'react-data-table-component';
import strings from '../strings';
import {Link} from 'react-router-dom';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
import AppTitle from '../AppTitle';


const SystemUsers = () => {

    const [userData, setUserData] = useState();
    const [listLoading, setListLoading] = useState();
    const [totalRows, setTotalRows] = useState();
    useEffect(() => {
        setListLoading(true)
        // get systems users
        getSystemUsers().then((res) => {
            if (res.err === 200) {
                setListLoading(false)
                setUserData(res.data);
                // console.log(res.data);
                setTotalRows(totalRows);
            }
        })
    }, []);

    const customStyles = {
        cells: {
            style: {
                borderRight: "1px solid #ddd",
                borderLeft: "1px solid #ddd"
            }
        }
    };
    
    const columns = [
        {
            name: 'Sr. No.',
            selector: (row, index) => index + 1,
            width: "70px"
        },
        {
            name: 'Avtar',
            selector: row => (
                <>
                    <img className="rounded-circle"
                        style={
                            {
                                width: "40px",
                                height: "40px"
                            }
                        }
                        src={
                            row.profile ? strings.ADMINPROFILE + row.profile : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                        }/>
                </>
            ),
            width: "75px"
        },
        {
            name: 'Name',
            selector: row => (`${
                row.userName
            }`)
        },
        {
            name: 'Phone Number',
            selector: row => (row.phoneNumber)

        }, {
            name: 'Email',
            selector: row => (row.email)

        }, {
            name: 'Actions',
            selector: row => (
                <>

                    <Link to={
                            `/viewuser/${
                                row._id
                            }`
                        }
                        className="p-1">
                        <CButton color="secondary" title="View User" variant="outline" className="ps-1 px-1">
                            <CIcon icon={
                                    icon.cilFolderOpen
                                }
                                size="lg"/>
                        </CButton>

                    </Link>
                    {/* <Link to={`/edituser/${row._id}`} className="p-1">
            <CButton
              color="secondary"
              title="Edit User"
              variant="outline"
              className="ps-1 px-1"
            >
              <CIcon icon={icon.cilPencil} size="lg" />
            </CButton>
          </Link> */}
                    <Link className="p-1">
                        <CButton color="danger" title="Delete Order " variant="outline"
                            // onClick={() => handleDeleteUser(row._id)}
                            className="px-1"
                        >
                            <CIcon icon={
                                    icon.cilTrash
                                }
                                size="lg"/>
                        </CButton>
                    </Link>
                </>
            ),
            width: "150px"
        },
    ];


    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true
    };

    return (
        <>
            <AppTitle title={"System Users"} />
            <div className='px-3 mt-4'>
                <CCard>
                    <CCardHeader>System Users</CCardHeader>
                    <CCardBody>
                        <DataTable columns={columns}
                            data={userData}
                            style={
                                {border: '1px solid black'}
                            }
                            highlightOnHover
                            pagination
                            paginationServer
                            progressPending={listLoading}
                            paginationRowsPerPageOptions={
                                []
                            }
                            paginationComponentOptions={paginationComponentOptions}
                            paginationTotalRows={totalRows}
                            customStyles={customStyles}
                            // onChangePage={handlePageChange}
                        />

                    </CCardBody>
                </CCard>
            </div>
        </>
    )
}

export default SystemUsers;
