import React, { useState, useEffect } from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormLabel,
    CRow,
    CSpinner,
    CAlert
} from "@coreui/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { getAllWithdrawalRequest, processWithdrawal } from "../Helper/Index.Api";
import AppTitle from "../AppTitle";

const WithdrawalRequest = () => {
    const [withdrawalRequest, setWithdrawalRequest] = useState([]);
    const [listLoading, setListLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        getAllWithdrawalRequest().then((res) => {
            console.log(res.data);
            setWithdrawalRequest(res.data);
            setListLoading(false);
        }).catch(err => {
            console.log(err);
            setListLoading(false);
        });
    }, []);

    const handleApprove = async (id) => {
        try {
            let payload = {
                requestId: id,
            };
            const response = await processWithdrawal(payload);

            if (response.err === 200) {
                setSuccessMessage("Withdrawal approved successfully!");
                setTimeout(() => setSuccessMessage(""), 5000); // Clear message after 5 seconds
                // Optionally, refresh the list or remove the item from state
                setWithdrawalRequest(prevState => prevState.filter(request => request._id !== id));
            } else {
                console.error("Unexpected response:", response);
            }
        } catch (error) {
            console.error("Error processing withdrawal:", error);
        }
    };

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: "#5470af",
                color: "white"
            }
        },
        cells: {
            style: {
                borderRight: "1px solid #ddd",
                borderLeft: "1px solid #ddd"
            }
        }
    };

    const buttonStyles = {
        color: "white",
        fontSize: "0.8rem", // Adjust font size as needed
        padding: "0.25rem 0.5rem" // Adjust padding if necessary
    };

    const columns = [
        {
            name: 'Sr. No.',
            selector: (row, index) => index + 1,
            width: "70px"
        },
        {
            name: 'Organizer',
            selector: row => (row?.organizer_id?.first_Name + " " + row?.organizer_id?.last_Name),
        },
        {
            name: 'Amount',
            selector: row => (row?.amount),
        },
        {
            name: 'Currency',
            selector: row => (row?.currency),
        },
        {
            name: 'Payment Status',
            selector: row => (row?.payment_status),
        },
        {
            name: 'Actions',
            selector: row => (
                <CButton
                    color="success"
                    onClick={() => handleApprove(row?._id)}
                    className="p-1"
                    style={buttonStyles}
                >
                    Approve
                </CButton>
            ),
            width: "150px"
        },
    ];

    return (
        <>
            <AppTitle title={"Withdrawal Request"} showbtn={true} path="/events" />
            <div className="px-lg-5 mt-lg-5 px-2 mt-2">
                <CCard>
                    <CCardHeader>
                        <strong>Withdrawal Request</strong>
                    </CCardHeader>
                    <CCardBody>
                        {successMessage && (
                            <CAlert color="success" className="text-center">
                                {successMessage}
                            </CAlert>
                        )}
                        {listLoading ? (
                            <div className="d-flex justify-content-center">
                                <CSpinner style={{ marginTop: "40px" }} color="info" />
                            </div>
                        ) : (
                            <DataTable
                                columns={columns}
                                data={withdrawalRequest}
                                customStyles={customStyles}
                                highlightOnHover
                            />
                        )}
                    </CCardBody>
                </CCard>
            </div>
        </>
    );
};

export default WithdrawalRequest;