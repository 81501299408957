import { axiosPrivate, axiosPublic } from "./axios";

// login api
export const loginApi = async (payloadData) => {
  let res = await axiosPrivate.post("/api/admin/login", payloadData);
  return res.data;
};

// forgot password api
export const forgotPass = async (payloadData) => {
  let res = await axiosPrivate.post("/api/admin/forgot-password", payloadData);
  return res.data;
}

// reset password api
export const resetPass = async (payloadData, key) => {
  let res = await axiosPrivate.post(`/api/admin/reset-password?key=${key}`, payloadData);
  return res.data;
}

// update user profile api
export const updateProfile = async (payloadData) => {
  let res = await axiosPrivate.post(`/api/admin/profileupdate`, payloadData);
  return res.data;
}

// checking admin key
export const checkAdminResetKey = async (payloadData) => {
  console.log(payloadData)
  let res = await axiosPublic.post("/api/admin/checkadminkey", payloadData);
  return res.data;
}