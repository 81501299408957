import React, { useEffect, useState } from 'react';
import swal from "sweetalert";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormFeedback,
  CInputGroup,
  CInputGroupText,
  CRow
} from '@coreui/react';
import Select from "react-select";
import CIcon from '@coreui/icons-react';
import { cilUser, cilLockLocked } from '@coreui/icons';
import {  getAllEvents } from '../Helper/Index.Api';
import { MdOutlineSignalWifiStatusbarConnectedNoInternet4 } from 'react-icons/md';

const EventForm = () => {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [eventData, setEventData] = useState({
        event_id: '',
        announcementContent: '',
      });
      const [Options, setOptins] = useState([]); 

     // get events
     useEffect(() => {
        getAllEvents().then((res) => {
            setEvents(res.data);
            return res;
        }).then((res) => {
            let selectOptins = res?.data?.map((el) => {
                return {
                    value: el._id, label: `${el.title }`
                }
            });
            setOptins(selectOptins)
        }).catch(err => console.log(err))
    }, [])

      const handleChange = (e) => {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
      };
      const handleSubmit = (e) => {
        e.preventDefault();
        if(eventData.event_id.length===0 || eventData.announcementContent.length===0){
            swal("Annoucement","Please Enter Your Event ID and annoucement.")
            return
          }
        // Add form validation and submission logic here
        // createAnnouncement(eventData).then(()=>{
        //     swal("Success!","Record saved.")
        // }).catch((err)=>{
        //     console.log(err);
        // })
        setValidated(true);
        if (e.currentTarget.checkValidity()) {
          setLoading(true);
          // Handle form submission
          console.log(eventData);
        }
      };
return(
     <div className="min-vh-100 d-flex flex-row align-items-center loginBG">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol xs={12} md={8} lg={6}>
            <CCardGroup>
              <CCard className="p-1 bg-dark text-white rounded-4" style={{ boxShadow: "rgba(0, 0, 0, 0.3) 10px 10px 10px", minWidth: "320px" }}>
                <CCardBody>
                  <CForm noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="row rounded-4 mb-2">
                      <div className="col-3"></div>
                      <div className="col-6 text-center">
                        <h4 className="text-white">Create Annoucement</h4>
                      </div>
                      <div className="col-3"></div>
                    </div>

                    <CInputGroup className="mb-3">
                      <CInputGroupText>Event</CInputGroupText>
                      <Select
                    placeholder="Select Event"
                    options={Options}
                    value={events}
                    onChange={(value) => setEvents(value)}
                    styles={{
                        container: (base) => ({
                        ...base,
                        width: '85%' // Set the width to 85% or any other width
                        }),
                        control: (base) => ({
                        ...base,
                        minWidth: '300px' // Adjust the minWidth as needed
                        }),
                        menu: (base) => ({
                        ...base,
                        backgroundColor: 'white', // Set dropdown background color
                        }),
                        option: (base, state) => ({
                        ...base,
                        color: state.isSelected ? 'white' : 'black', // Text color when selected and unselected
                        backgroundColor: state.isSelected ? '#007bff' : 'white', // Background color for selected and unselected
                        '&:hover': {
                            backgroundColor: '#007bff', // Background color on hover
                            color: 'white', // Text color on hover
                        },
                        }),
                    }}
                    />
                     </CInputGroup>
                    {/* Description */}
                    <CInputGroup className="mb-3">
                      <CInputGroupText>Description</CInputGroupText>
                      <CFormInput
                        placeholder="Announcement Content"
                        name="announcementContent" 
                        value={eventData.announcementContent}
                        onChange={handleChange}
                        required
                      />
                      <CFormFeedback invalid>Please enter announcement content.</CFormFeedback>
                    </CInputGroup>

                    {/* Submit Button */}
                    <CRow>
                      <CCol xs={12}>
                        <CButton
                          color="primary"
                          className="px-4 w-100"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? "Creating..." : "Create Annoucement"}
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
);
}

export default EventForm;