import React, { useContext } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function ProtectedRoutes() {
  // const { auth } = useAuth();
  let navigate = useNavigate();
  let token = localStorage.getItem("token");
  const location = useLocation();

  return token !== null ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

export default ProtectedRoutes;
