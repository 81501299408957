import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteuserPost, getSinglePost } from '../Helper/Index.Api';
import strings from '../strings';
import { cilDelete } from '@coreui/icons';
import Slider from 'react-slick';
import { CContainer, CSpinner } from '@coreui/react';
import swal from 'sweetalert';
import AppTitle from '../AppTitle';
import { MdDelete } from "react-icons/md";
const moment = require("moment");

const ViewReportedPost = () => {
    let { rpid, uid } = useParams();
    const [postdata, setPostData] = useState();
    const [listLoading, setListLoading] = useState(false);
    const navigate = useNavigate();

    const deleteStyle = {
        backgroundColor: "#ffffff",
        padding: "10px 20px",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px"
    }

    // delete button style
    const deleteBtnStyle = {
        padding: "0px 20px",
        borderRadius: "10px",
        color: "white",
        backgroundColor: "#e55353",
        border: "none"
    }

    const heartStyle = {
        float: "end"
    }

    useEffect(() => {
        let data = {
            postId: rpid,
            loginUserId: uid
        }
        setListLoading(true)
        // sinlge reported post
        getSinglePost(data).then((res) => {
            // console.log("response:", res.data)
            setListLoading(false)
            if (res.err === 200) {
                setPostData(res.data)
                // console.log(res.data);
            }
        });
    }, []);

    // carousel settings
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    // rendering media files
    const renderMediaFile = (file, index) => {
        if (file.metadata?.contentType === "image/*") {
            return (
                <img key={index}
                    src={  file.location  }
                    alt='User Posted Image'
                    className='posted-image'
                    style={
                        {
                            width: '100%',
                            height: '600px',
                            objectFit: "contain"
                        }
                    } />
            );
        } else if (file.metadata?.contentType === "video/*") {
            return (
                <video key={index}
                    src={ file.location }
                    type={ file.type }
                    controls
                    className='posted-video'
                    style={
                        {
                            width: '100%',
                            height: '600px',
                            objectFit: "contain"
                        }
                    }></video>
            );
        }
    };

    // block user functionality
    const handleBlock = (e) => {
        const data = {
            postId: rpid,
            createdBy: uid
        }
        swal({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete this post?',
            icon: 'warning',
            dangerMode: true,
            buttons: ['Cancel', 'Ok']
        }).then((ok) => {
            if (ok) {
                swal('Success', "Post blocked", 'success');
                deleteuserPost(data).then((res) => {
                    if (res.err === 200) {
                        swal('Success', res.msg, 'success');
                        // getuserPosts();
                        navigate("/reportedposts")
                    } else {
                        swal('Warning', res.msg, 'warning');
                    }
                })
                    .catch((error) => console.log(error));
            }
        });
    }

    return (
        <> {
            listLoading ? (
                <>
                    <div className='text-center mt-5'>
                        <CSpinner color='primary' />
                    </div>
                </>
            ) : (
                <>
                    <AppTitle title="Reported Post(Details)"
                        showbtn={true}
                        path={`/reportedposts`} />
                    <CContainer className='px-lg-5 mt-lg-5 px-2 mt-2'>
                        <div style={deleteStyle} className={`${postdata?.mediaFiles?.length === 0 ? "col-lg-12 mx-auto" : "col-lg-12"}`} >
                            <p>Do you want to delete this post.</p>
                            <button style={deleteBtnStyle} onClick={() => handleBlock()}>Delete</button>
                        </div>
                        <div>
                            <div className="row">
                                {
                                    postdata?.mediaFiles?.length === 0 ? (
                                        <div className='d-block d-lg-flex'>
                                            <div className="col-12 col-lg-8 bg-white text-dark text-start p-3"
                                                style={
                                                    { height: "600px" }
                                                }>
                                                <div className='d-flex'>
                                                    <div className="user-profile1">
                                                        <img src={
                                                            postdata?.createdBy?.userAvatar?.location ? postdata?.createdBy?.userAvatar?.location : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                        }
                                                            alt="User Avatar"
                                                            className="profile-photo" />
                                                    </div>
                                                    <div className="user-details1 mt-1">
                                                        <div className="user-name">
                                                            {
                                                                `${postdata?.createdBy?.first_Name
                                                                } ${postdata?.createdBy?.last_Name
                                                                }`
                                                            }</div>
                                                        <div>{
                                                            postdata?.timeElapsed
                                                        }</div>
                                                        <div className='d-flex flex-row-reverse'>
                                                            <div>
                                                                <i class="fa fa-heart text-danger" aria-hidden="true"></i>&nbsp;{
                                                                    postdata?.likeCount
                                                                }</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className='container' />
                                                <div className='text-dark mt-4'>
                                                    {
                                                        postdata?.message?.length > 350 ? postdata?.message.slice(0, 350) + "..." : postdata?.message
                                                    } </div>
                                            </div>
                                            <div className='border'></div>
                                            <div className='text-dark bg-white col-12 col-lg-4 px-2 py-4' style={{ overflow: "auto" }}>
                                                <p>Reported Message</p>
                                                {
                                                    postdata?.reports?.map((el) => {
                                                        return <div style={
                                                            { display: "flex", marginBottom: "10px", }
                                                        }
                                                            className='gap-1'>
                                                            <img className=''
                                                                style={
                                                                    {
                                                                        width: "40px",
                                                                        height: "40px",
                                                                        borderRadius: "50%"
                                                                    }
                                                                }
                                                                src={
                                                                    el?.userId?.userAvatar?.location ? el?.userId?.userAvatar?.location : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                                }
                                                                alt="image" />
                                                            <div style={{ width: "80%" }}>
                                                                <p style={{ fontSize: "14px", marginBottom: "2px" }}>{el?.userId?.first_Name + " " + el.userId?.last_Name}</p>
                                                                <div style={
                                                                    {
                                                                        display: "inline-block",
                                                                        backgroundColor: "#f3f4f8",
                                                                        color: "#000000",
                                                                        wordWrap: "break-word",
                                                                        position: "relative",
                                                                        marginBottom: "10px",
                                                                        width: "100%",
                                                                        marginTop: "10px"
                                                                    }
                                                                }
                                                                    className='px-4 py-1 rounded-2'>

                                                                    {<p style={
                                                                        {
                                                                            fontSize: "16px",
                                                                            marginTop: "10px"
                                                                        }
                                                                    }>
                                                                        {

                                                                            el?.reportMessage
                                                                        }</p>
                                                                    }
                                                                    {
                                                                        <p style={
                                                                            {
                                                                                fontSize: "16px",
                                                                                marginTop: "10px"
                                                                            }
                                                                        }>
                                                                            {

                                                                                el?.reply
                                                                            }</p>
                                                                    }
                                                                    <div style={
                                                                        {
                                                                            fontSize: "12px",
                                                                            position: "absolute",
                                                                            top: 3,
                                                                            right: 6

                                                                        }
                                                                    }>
                                                                        {
                                                                            moment(el?.createdAt).format("DD MMM YYYY HH:MM A")
                                                                        } </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                } </div>
                                        </div>
                                    ) : (
                                        <div className='d-block d-lg-flex'>
                                            <div className="col-12 col-lg-8 text-white text-center"
                                                style={
                                                    { backgroundColor: "rgba(79, 113, 189, 0.39)" }
                                                }>
                                                <div className=''>
                                                    {
                                                        postdata?.mediaFiles.length > 0 ? (
                                                            <>
                                                                <Slider {...settings}>
                                                                    {
                                                                        postdata?.mediaFiles.map((file, index) => (
                                                                            <div key={index}>
                                                                                {
                                                                                    renderMediaFile(file, index)
                                                                                }</div>
                                                                        ))
                                                                    } </Slider>
                                                                {postdata?.message && <div
                                                                    className='p-2'
                                                                    style={
                                                                        {
                                                                            width: "100%",
                                                                        }
                                                                    }>
                                                                    <div className='text-start'>
                                                                        {
                                                                            postdata?.message
                                                                        } </div>
                                                                </div>}
                                                            </>
                                                        ) : (
                                                            <div className="align-self-center"
                                                                style={
                                                                    {
                                                                        width: "100%",
                                                                        height: "600px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center"
                                                                    }
                                                                }>
                                                                <div className=''>
                                                                    {
                                                                        postdata?.message
                                                                    } </div>
                                                            </div>
                                                        )
                                                    } </div>
                                            </div>
                                            <div className="col-12 col-lg-4 bg-white text-dark text-start p-3">
                                                <div className='d-flex'>
                                                    <div className="">
                                                        <img src={
                                                            postdata?.createdBy?.userAvatar?.location ? postdata?.createdBy?.userAvatar?.location : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                        }
                                                            alt="User Avatar"
                                                            className="profile-photo" />
                                                    </div>
                                                    <div className="user-details mt-1" >
                                                        <div className="user-name">
                                                            {
                                                                `${postdata?.createdBy?.first_Name
                                                                } ${postdata?.createdBy?.last_Name
                                                                }`
                                                            }</div>
                                                        <div>{
                                                            postdata?.timeElapsed
                                                        }</div>
                                                        <div className='d-flex'>
                                                            <div>
                                                                <i class="fa fa-heart text-danger" aria-hidden="true"></i>&nbsp;{
                                                                    postdata?.likeCount
                                                                }</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className='container' />
                                                <div className='text-dark mt-4' style={{ height: "60vh", overflow: "auto" }}>
                                                    <p>Reported Message</p>
                                                    {
                                                        postdata?.reports?.map((el) => {
                                                            return <div style={
                                                                { display: "flex", marginBottom: "10px", }
                                                            }
                                                                className='gap-1'>
                                                                <img className=''
                                                                    style={
                                                                        {
                                                                            width: "40px",
                                                                            height: "40px",
                                                                            borderRadius: "50%"
                                                                        }
                                                                    }
                                                                    src={
                                                                        el?.userId?.userAvatar?.location ? el?.userId?.userAvatar?.location : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                                    }
                                                                    alt="image" />
                                                                <div style={{ width: "80%" }}>
                                                                    <p style={{ fontSize: "14px", marginBottom: "2px" }}>{el?.userId?.first_Name + " " + el.userId?.last_Name}</p>
                                                                    <div style={
                                                                        {
                                                                            display: "inline-block",
                                                                            backgroundColor: "#f3f4f8",
                                                                            color: "#000000",
                                                                            wordWrap: "break-word",
                                                                            position: "relative",
                                                                            marginBottom: "10px",
                                                                            width: "100%"
                                                                        }
                                                                    }
                                                                        className='px-4 py-1 rounded-2'>

                                                                        {<p style={
                                                                            {
                                                                                fontSize: "16px",
                                                                                marginTop: "15px"
                                                                            }
                                                                        }>
                                                                            {

                                                                                el?.reportMessage
                                                                            }</p>
                                                                        }
                                                                        {
                                                                            <p style={
                                                                                {
                                                                                    fontSize: "16px",
                                                                                    marginTop: "10px"
                                                                                }
                                                                            }>
                                                                                {

                                                                                    el?.reply
                                                                                }</p>
                                                                        }
                                                                        <div style={
                                                                            {
                                                                                fontSize: "12px",
                                                                                position: "absolute",
                                                                                top: 3,
                                                                                right: 6

                                                                            }
                                                                        }>
                                                                            {
                                                                                moment(el?.createdAt).format("DD MMM YYYY HH:MM A")
                                                                            } </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                    } </div>
                                            </div>
                                        </div>
                                    )
                                } </div>
                        </div>
                    </CContainer>
                </>
            )
        } </>
    )
}

export default ViewReportedPost;
// position-absolute top-50 start-0 translate-middle-y p-2
