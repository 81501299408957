import { axiosPrivate } from "../../components/Helper/axios"
import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS } from "./actionTypes"

export const LoginApi = (payloadData) => async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST })
    try {
        const res = await axiosPrivate.post("/api/admin/login", payloadData);
        console.log("res:", res.data.data)
        dispatch({ type: LOGIN_SUCCESS, payload: res.data.data })
        return { status: res.data.err, msg: res.data.msg }
    }
    catch (err) {
        dispatch({ type: LOGIN_FAILURE })
        return { status: err.response.data.err, msg: err.response.data.msg }
    }
}